import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styles from "./Button.module.scss";
import { cn } from "../../lib/helpers";

const Button = ({ children, type, submit, href }) => {
  if (href && submit) console.error("Link tags can't be of type submit");

  const ButtonWrapper = ({ children, href, ...props }) => {
    if (href) {
      return href.includes("http") ? (
        <a href={href} {...props}>
          {children}
        </a>
      ) : (
        <Link to={href} {...props} activeClassName={styles.activeLink}>
          {children}
        </Link>
      );
    } else {
      return <button {...props}>{children}</button>;
    }
  };

  return (
    <ButtonWrapper
      type={submit ? "submit" : "button"}
      href={href}
      className={cn(styles.button, styles[type])}
    >
      {children}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
};

export default Button;
