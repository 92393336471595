import React from "react";

export default {
  add: <path d="M20 11.5v1h-7.5V20h-1v-7.5H4v-1h7.5V4h1v7.5H20z" />,
  edit: (
    <path d="M21 5.944c0 .387-.073.762-.22 1.125-.146.364-.36.686-.641.967L8.563 19.611 3 21l1.389-5.563L15.964 3.86c.281-.28.603-.495.967-.641.363-.147.738-.22 1.125-.22.404 0 .785.078 1.142.233a3 3 0 01.936.633c.267.266.478.578.633.936.155.357.233.738.233 1.142zM5.865 15.551a4.012 4.012 0 012.584 2.584l9.501-9.51-2.575-2.575-9.51 9.5zm-1.318 3.902l2.891-.72a2.83 2.83 0 00-2.17-2.172l-.721 2.892zM18.75 7.825c.146-.146.287-.284.422-.413s.255-.265.36-.409a1.686 1.686 0 00.343-1.05c0-.252-.048-.488-.145-.707a1.888 1.888 0 00-.976-.976 1.737 1.737 0 00-.707-.145c-.223 0-.418.03-.585.092a1.882 1.882 0 00-.465.25c-.144.106-.28.226-.41.361-.128.135-.266.275-.412.422l2.575 2.575z" />
  ),
  hamburger: <path d="M2.25 18.003h19.5m-19.5-6h19.5m-19.5-6h19.5" />,
  close: <path d="M18.5 18.75L5.25 5.5m0 13.25L18.5 5.5" />,
  file: (
    <>
      <path d="M5.888 2.223c-.672 0-1.21.55-1.21 1.222l-.012 17.111c0 .672.538 1.222 1.222 1.222H18.11c.673 0 1.223-.55 1.223-1.222V8.334l-6.111-6.111H5.888zm7.334 6.11V3.446l4.888 4.889h-4.888z" />
    </>
  ),
  search: (
    <>
      <g clipPath="url(#clip0)">
        <path d="M10.642 18.153a9.063 9.063 0 117.09-16.682 9.063 9.063 0 01-7.09 16.682zM7.779 16.22L.75 23.25" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path transform="matrix(-1 0 0 1 24 0)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
  phone: (
    <path d="M19.166 14.156a2.5 2.5 0 00-3.536 0l-.386.385a46.889 46.889 0 01-5.79-5.79l.387-.385a2.5 2.5 0 000-3.535L7.718 2.71a2.556 2.556 0 00-3.535 0L3.019 3.874a3.507 3.507 0 00-.441 4.4 46.944 46.944 0 0013.14 13.145 3.549 3.549 0 004.4-.443l1.165-1.164a2.5 2.5 0 000-3.535l-2.117-2.121z" />
  ),
  email: (
    <>
      <path d="M11.335 13.917a.939.939 0 001.326 0l7.265-7.264a.375.375 0 00-.21-.637A1.474 1.474 0 0019.5 6h-15c-.073 0-.145.005-.217.016a.375.375 0 00-.21.636l7.262 7.265z" />
      <path d="M20.916 7.374a.137.137 0 00-.15.03l-4.65 4.65a.188.188 0 000 .265l3.782 3.782a.562.562 0 11-.795.796l-3.782-3.782a.189.189 0 00-.205-.041.189.189 0 00-.06.04l-1.597 1.597a2.062 2.062 0 01-2.916 0l-1.598-1.595a.188.188 0 00-.266 0l-3.782 3.782a.563.563 0 01-.795-.796l3.782-3.782a.188.188 0 000-.265l-4.65-4.65a.135.135 0 00-.15-.03A.136.136 0 003 7.5v9A1.5 1.5 0 004.5 18h15a1.5 1.5 0 001.5-1.5v-9a.135.135 0 00-.084-.126z" />
    </>
  ),
  arrowRight: <path d="M23.25 12.497H.75M19.5 16.247l3.75-3.75-3.75-3.75" />,
  external: (
    <path d="M19.585 8.662A11.969 11.969 0 00.75 18.5a7.5 7.5 0 017.5-7.5 7.412 7.412 0 015.972 3.028l-3.192 3.189a.75.75 0 00.531 1.28H21.75a1.5 1.5 0 001.5-1.5V6.808a.75.75 0 00-1.28-.531z" />
  ),
};
