import React from "react";
import styles from "./Footer.module.scss";
import logo from "../../images/kppfLogo.svg";

const Footer = () => (
  <footer className={styles.footer}>
    <a aria-label="Kronprinsparets fond" href="https://kronprinsparetsfond.no">
      <img src={logo} alt="Kronprinsparets fond logo" />
    </a>
  </footer>
);

export default Footer;
