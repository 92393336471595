import { useEffect } from "react";
import { getPageColor, setColor } from "../lib/helpers";

const useColorHook = (url) => {
  useEffect(() => {
    (function updateColor() {
      const pageColor = getPageColor(url);
      setColor("--background-color", pageColor.name, pageColor.value);
      setColor("--text-color", pageColor.name, 900);
      for (let i = 100; i < 900; i += 100) {
        setColor(`--current-theme-color-${i}`, pageColor.name, i);
      }
    })();
  }, [url]);
};

export default useColorHook;
