import React from "react";
import PropTypes from "prop-types";

import Footer from "../Footer/Footer";
import Nav from "../Nav/Nav";
import useColorHook from "../../hooks/useColorHook";
import { cn } from "../../lib/helpers";
import styles from "./Layout.module.scss";

const Layout = ({ children, standardPadding, location, maxWidth }) => {
  const currentUrl = location ? location.href : "";
  useColorHook(currentUrl);

  return (
    <div className={styles.pageWrapper}>
      <Nav location={location} />
      <main
        className={cn(
          styles.pageContent,
          standardPadding ? styles.standardPadding : "",
          maxWidth ? styles.maxWidth : ""
        )}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
