import React from "react";
import PropTypes from "prop-types";
import iconlib from "./iconlib";

const getIcon = (symbol) => {
  if (Object.getOwnPropertyNames(iconlib).includes(symbol)) {
    return iconlib[symbol];
  } else {
    console.error(`The icon: "${symbol}" does not exist.`);
  }
};

const Icon = ({ icon, size, color, strokeWidth }) => {
  if (!icon) return null;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {getIcon(icon)}
    </svg>
  );
};

Icon.defaultProps = {
  color: "currentColor",
  size: 24,
  strokeWidth: 1.5,
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Icon;
