import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Button from "../Button/Button";
import styles from "./Nav.module.scss";
import Icon from "../icon";
import { cn } from "../../lib/helpers";
import flytLogo from "../../images/flyt-logo.png";

const ComboLogo = () => <img className={styles.logo} src={flytLogo} />;

const Nav = () => {
  const {
    sanityFlytNavigation: { navigationItems },
  } = useStaticQuery(NAV_QUERY);
  const sanityDocumentIdToSlug = (id) =>
    ({
      "flyt-theoryOverview": "/teori",
      "flyt-partners": "/partnere",
      "flyt-contactPage": "/kontakt",
      omFlyt: "/om-flyt",
    }[id]);
  const [showHamburger, setShowHamburger] = useState(false);
  const toggleHamburger = () => setShowHamburger(!showHamburger);

  return (
    <nav className={cn(styles.nav, showHamburger ? styles.open : "")}>
      <Link to="/">
        <ComboLogo />
      </Link>
      <button
        onClick={toggleHamburger}
        className={styles.hamburger}
        aria-label="Åpne menyen"
      >
        <Icon icon={showHamburger ? "close" : "hamburger"} />
      </button>
      <ul className={cn(styles.navItemList, showHamburger ? styles.open : "")}>
        {navigationItems.map((link) => {
          const href = link.href ? link.href : sanityDocumentIdToSlug(link._id);
          const name = link.href ? link.name : link.title;
          return (
            <li key={`navigation-link-${href}`} className={styles.navItem}>
              <Button type="tertiary" href={href}>
                {name}
              </Button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const NAV_QUERY = graphql`
  query {
    sanityFlytNavigation(_id: { regex: "/flyt-Navigation$/" }) {
      navigationItems {
        ... on SanityLink {
          href
          name
        }
        ... on SanityContactPage {
          _id
          title
        }
        ... on SanityPartners {
          _id
          title
        }
        ... on SanityTheoryOverview {
          _id
          title
        }
        ... on SanityOmFlyt {
          _id
          title
        }
      }
    }
  }
`;

export default Nav;
