import imageUrlBuilder from "@sanity/image-url";
import client from "../../client";
import slugifyRaw from "slugify";

export function urlFor(source) {
  const builder = imageUrlBuilder(client);
  return builder.image(source);
}

export const slugify = (url) => slugifyRaw(url, { lower: true });

export const cn = (...classes) => classes.join(" ");

export const trimSlashes = (string) => string.split("/");

export const setCSSProperty = (propertyName, propertyValue) => {
  const root = document.documentElement;
  root.style.setProperty(propertyName, propertyValue);
};
export const setColor = (propertyName, colorName, colorValue) => {
  setCSSProperty(propertyName, `var(--color-${colorName}-${colorValue})`);
};

export const getPageColor = (pageUrl) => {
  const isSubpage = pageUrl.split("/")[4];
  pageUrl = trimSlashes(pageUrl);
  pageUrl = pageUrl[3] === "" ? "frontpage" : pageUrl[3];
  switch (pageUrl) {
    case "frontpage":
      return { name: "krikkand", value: 400 };
    case "om-flyt":
      return { name: "krikkand", value: 300 };
    case "kontakt":
      return { name: "bark", value: 200 };
    case "teori":
      return { name: "mose", value: isSubpage ? 200 : 400 };
    case "partnere":
      return { name: "bever", value: 200 };
    case "alle-kommuner":
      return { name: "krikkand", value: 300 };
    default:
      return { name: "skifer", value: 100 };
  }
};

export const getYearList = () => {
  const listStartYear = new Date().getFullYear() + 1;
  return Array.from({ length: listStartYear - 2019 + 1 }, (_, i) => {
    const year = listStartYear - i;
    return {
      title: `${year}/${year + 1}`,
      value: `${String(year).slice(2)}/${String(year + 1).slice(2)}`,
    };
  });
};

export const getCurrentMonth = () => new Date().getMonth() + 1;

export const getCurrentYear = (year = new Date().getFullYear()) => {
  const yearDelta = new Date().getFullYear() - year;
  const yearList = getYearList();
  return getCurrentMonth() < 8
    ? yearList[2 + yearDelta]
    : yearList[1 + yearDelta];
};

export const isPreviousYear = (year) => {
  function parseIntFromYear(y) {
    return parseInt(y.split("/")[1]);
  }
  const currentYear = parseIntFromYear(getCurrentYear().value);
  const groupYear = parseIntFromYear(year);
  return groupYear < currentYear;
};

export const mutateKommuneList = (kommuneList, groupList) =>
  kommuneList.map(({ node: { name, _id } }) => {
    // Check if the current years group exist
    let group = groupList
      .filter(({ node: group }) => group?.kommune?.name)
      .find(
        ({ node: group }) =>
          group.kommune._id === _id && group.year === getCurrentYear().value
      );
    // If not, return the newest
    if (!group) {
      group = groupList
        .filter(({ node: group }) => group?.kommune?.name)
        .find(({ node: group }) => group.kommune._id === _id);
    }
    const city = group?.node?.kommune?.city;
    return {
      _id,
      name: city ? `${name} (${city})` : name,
      groupUrl: group
        ? `/${slugify(group.node.kommune.name)}/${group.node.year.replace(
            "/",
            "-"
          )}`
        : null,
    };
  });
